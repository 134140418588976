const messages = {
  de: {
  },
  fr: {
  },
  en: {
  }
};

export default messages;
